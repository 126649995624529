<template>
  <div class="wrapper">
    <div class="space-50"></div>
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
      >
        <div class="md-card md-card-blog md-theme-default">
          <div class="md-card-content">
            <h6 class="card-category text-info">Empresa</h6>
            <p class="card-description">
              A S3 BIOTECH se propõe à integração de diferentes bancos de dados
              em painéis interativos e de fácil manuseio. As inúmeras
              possibilidades de construção de imagens e gráficos
              autoexplicativos, impactantes, com dados atualizados relacionados
              com a realidade local, assim como a organização de evidências
              sobre diversas condições e agravos de saúde agregam valor de
              difícil mensuração à proposta apresentada. Ressalta-se por exemplo
              painéis com dados de Dengue, Zika, Leishmanioses, Cobertura de
              Vacinação, desperdício de doses e mais atualmente visualização de
              dados sobre Coronavírus.
            </p>
          </div>
          <!---->
        </div>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
      >
        <div class="md-card md-card-blog md-theme-default">
          <router-link to="/mosaico">
            <div class="md-card-header md-card-header-image">
              <img :src="dashboard" class="img" />
              <div class="card-title">
                Mosaico DashBoard
              </div>
              <div
                class="colored-shadow"
                :style="
                  `background-image: url(&quot;${dashboard}&quot;); opacity: 1;`
                "
              ></div>
            </div>
          </router-link>
          <div class="md-card-content">
            <h6 class="card-category text-info">Produto</h6>
            <p class="card-description">
              O Sistema utiliza plataforma web, alinhado com os principais
              sistemas de gestão pública de saúde que sirvam de suporte para
              análise estatística, série histórica, geo georreferenciamento e
              que se traduzam em informações úteis para a saúde. O produto foi
              pensado para atender uma necessidade dos gestores públicos de
              saúde que contam hoje com vários sistemas e subsistema de saúde e
              epidemiológico, porém fragmentados e que não fornecem uma visão
              ágil e global da situação de saúde. O mosaico da S3Biotech conta
              com gráficos e mapas que fornecem diversas abordagens para análise
              de situação de saúde pública, com ágil tempo de resposta e
              tornando o conhecimento do problema viável, tangível e
              visualizável.
            </p>
          </div>
          <!---->
        </div>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
      >
        <div class="md-card md-card-blog md-theme-default">
          <div class="md-card-content">
            <h6 class="card-category text-info">Inovação</h6>
            <p class="card-description">
              A S3 Biotech atualmente trabalha em conjunto com o Comitê Gestor
              de Crise da UFPI para o combate do novo coronavírus e auxilia na
              análise e apresentação de dados para o COE e governo do estado do
              Piauí. A equipe é capaz de implementar a combinação dos dados de
              diferentes fontes de forma benéfica para análise; gerenciar grande
              volume de dados; direcionar a montagem dos painéis baseados em
              dados científicos e acurados; apresentar os painéis
              epidemiológicos interativos capazes de utilização em plataforma
              web em computadores e em dispositivos móveis; realizar análises
              estatísticas para estabelecimento de significâncias para o
              objetivo maior de transformar painéis de dados em políticas
              públicas para a gestão em saúde.
            </p>
          </div>
          <!---->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    dashboard: {
      type: String,
      default: require("@/assets/img/dashboard.png")
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped></style>
