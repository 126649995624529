<template>
  <div class="main main-raised">
    <div class="section section-about">
      <div class="container">
        <div class="title">
          <h2>Quem Somos!</h2>
        </div>
        <about></about>
      </div>
    </div>
    <div class="section section-maps">
      <div class="container">
        <div class="title">
          <h2>
            Demostração de mapa Ocupação Hospitalar
            <md-button to="/mosaico/" class="md-primary md-round">
              <i class="material-icons">bar_chart</i>
              Acesse Mosaico DashBoard
            </md-button>
          </h2>
        </div>
        <health-unit-map
          sourceProp="sesapi"
          datasetNameProp="occupation_of_health_units"
          typeProp="microregion_sesapi_covid"
        ></health-unit-map>
      </div>
    </div>
  </div>
</template>

<script>
import About from "./components/AboutSection";
import HealthUnitMap from "../health_unit/HealthUnitMap";
export default {
  components: {
    About,
    HealthUnitMap
  },
  name: "index",
  bodyClass: "index-page",
  props: {},
  data() {
    return {
      firstname: null,
      email: null,
      password: null,
      leafShow: false
    };
  },
  methods: {
    leafActive() {
      if (window.innerWidth < 768) {
        this.leafShow = false;
      } else {
        this.leafShow = true;
      }
    }
  },
  mounted() {
    this.leafActive();
    window.addEventListener("resize", this.leafActive);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.leafActive);
  }
};
</script>
<style lang="scss"></style>
